body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Fonts */
@font-face {
  font-family: "PPNeueMontrealBold";
  src: url("./assets/fonts/PPNeueMontreal-Bold.otf") format("opentype")
}
@font-face {
  font-family: "PPNeueMontrealBook";
  src: url("./assets/fonts/PPNeueMontreal-Book.otf") format("opentype")
}
@font-face {
  font-family: "PPNeueMontrealItalic";
  src: url("./assets/fonts/PPNeueMontreal-Italic.otf") format("opentype")
}
@font-face {
  font-family: "PPNeueMontrealMedium";
  src:  url("./assets/fonts/PPNeueMontreal-Medium.otf") format("opentype")
}
@font-face {
  font-family: "PPNeueMontrealRegular";
  src: url("./assets/fonts/NeueMontreal-Regular.otf") format("opentype")
}

@font-face {
  font-family: "PPNeueMontrealSemiBolditalic";
  src: url("./assets/fonts/PPNeueMontreal-SemiBolditalic.otf") format("opentype")
}
@font-face {
  font-family: "PPNeueMontrealThin";
  src: url("./assets/fonts/PPNeueMontreal-Thin.otf") format("opentype")
}

@font-face {
  font-family: "ComforterRegular";
  src: url("./assets/fonts/Comforter-Regular.ttf") format("truetype")
}

@font-face {
  font-family: "ApercuProBlack";
  src: url("./assets/fonts/ApercuPro-Black.ttf") format("truetype")
}
@font-face {
  font-family: "ApercuProBlackItalic";
  src: url("./assets/fonts/ApercuPro-BlackItalic.ttf") format("truetype")
}
@font-face {
  font-family: "ApercuProBold";
  src: url("./assets/fonts/ApercuPro-Bold.ttf") format("truetype")
}
@font-face {
  font-family: "ApercuProBoldItalic";
  src: url("./assets/fonts/ApercuPro-BoldItalic.ttf") format("truetype")
}

@font-face {
  font-family: "ApercuProExtraLight";
  src: url("./assets/fonts/ApercuPro-ExtraLight.ttf") format("truetype")
}
@font-face {
  font-family: "ApercuProExtraLightItalic";
  src: url("./assets/fonts/ApercuPro-ExtraLightItalic.ttf") format("truetype")
}
@font-face {
  font-family: "ApercuProItalic";
  src: url("./assets/fonts/ApercuPro-Italic.ttf") format("truetype")
}
@font-face {
  font-family: "ApercuProLight";
  src: url("./assets/fonts/ApercuPro-Light.ttf") format("truetype")
}

@font-face {
  font-family: "ApercuProLightItalic";
  src: url("./assets/fonts/ApercuPro-LightItalic.ttf") format("truetype")
}
@font-face {
  font-family: "ApercuProMedium";
  src: url("./assets/fonts/apercu_medium_pro.otf") format("opentype")
}
@font-face {
  font-family: "ApercuProMediumItalic";
  src: url("./assets/fonts/ApercuPro-MediumItalic.ttf") format("truetype")
}
@font-face {
  font-family: "ApercuProRegular";
  src: url("./assets/fonts/ApercuPro-Regular.ttf") format("truetype")
}

@font-face {
  font-family: "ApercuProLightThin";
  src: url("./assets/fonts/ApercuPro-Thin.ttf") format("truetype")
}
@font-face {
  font-family: "ApercuProThinItalic";
  src: url("./assets/fonts/ApercuPro-ThinItalic.ttf") format("truetype")
}











