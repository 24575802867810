
::-webkit-scrollbar {
  width: 0px;
}

/*
::-webkit-scrollbar-track {
  background: transparent;
  width: 10px;

}
/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #D8D8D8;
  border-radius: 100vw;
  height: 5px;
} */
