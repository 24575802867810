.popup-enter {
    opacity: 0;
    transform: scale(0.3);
  }
  .popup-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 2500ms, transform 2500ms;
  }
  .popup-exit {
    opacity: 1;
  }
  .popup-exit-active {
    opacity: 0;
    transform: scale(0.1);
    transition: opacity 1000ms, transform 1000ms;
  }

